'use client'

import React, { useEffect, useState, useRef } from "react";
import {
    CardHeader,
    CardBody,
    Typography,
    Button,
    Dialog,
    DialogHeader,
    DialogFooter,
    Input,
} from "@material-tailwind/react";
import axios from "axios";
import { motion } from "framer-motion";
import { LuListFilter } from "react-icons/lu";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { FaBan } from "react-icons/fa";
import { PuffLoader } from "react-spinners";

interface Asset {
    id: string;
    images_url: {
        AssetImagesURL: string[];
    };
    title: string;
    description: string;
    user_data: {
        user: {
            profile_image: string;
            uname: string;
        }
    }
    sold: boolean;
}

interface LongDialogProps {
    onSearch: (id: string) => void;
}

export function LongDialog({ onSearch }: LongDialogProps) {
    const [open, setOpen] = useState(false);
    const [searchId, setSearchId] = useState("");

    const handleOpen = () => setOpen(!open);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchId(e.target.value);
    };

    const handleConfirm = () => {
        onSearch(searchId);
        handleOpen();
    };

    return (
        <div className="absolute top-4 left-4">
            <div 
                className="bg-gray-100 w-36 h-12 text-xl flex justify-center items-center rounded-full cursor-pointer hover:bg-black hover:text-white" 
                onClick={handleOpen}
            >
                <LuListFilter />
            </div>
            <Dialog open={open} handler={handleOpen} size="sm">
                <DialogHeader className="font-main">
                    กรองอสังหาฯ (Filter Assets)
                </DialogHeader>
                <div className="p-4">
                    <div className="mb-4">
                        <Typography variant="small" className="mb-2 font-main">
                            ค้นหาด้วย ID (Search by ID)
                        </Typography>
                        <Input
                            crossOrigin={undefined}
                            type="text"
                            placeholder="Enter asset ID..."
                            value={searchId}
                            onChange={handleSearch}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleConfirm();
                                }
                            }}
                            className="!border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500"
                            containerProps={{ className: "min-w-[100px]" }}
                            labelProps={{
                                className: "hidden",
                            }}
                        />
                    </div>
                </div>
                <DialogFooter className="space-x-2">
                    <Button 
                        variant="text" 
                        color="blue-gray" 
                        onClick={handleOpen}
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="gradient" 
                        className="bg-black"
                        onClick={handleConfirm}
                    >
                        Confirm
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default function PostAssets() {
    const containerRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState(1);
    const [assetsData, setAssetsData] = useState<Asset[]>([]);
    const [loading, setLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const getAssets = async (pageNum: number, search?: string) => {
        try {
            setLoading(true);

            let response;
            if (search && search.trim()) {
                response = await axios.get(`${process.env.REACT_APP_API}/product/${search.trim()}`);
                if (response.data) {
                    const data = response.data.asset;
                    setAssetsData([data]);
                }
            } else {
                response = await axios.get(`${process.env.REACT_APP_API}/asset/getMultipleAsset?page=${pageNum}`);
                const assets: Asset[] = response.data.assets;
                
                if (pageNum === 1) {
                    setAssetsData(assets);
                } else {
                    setAssetsData(prev => [...prev, ...assets]);
                }
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
            if (search && search.trim()) {
                setAssetsData([]);
            }
        } finally {
            setLoading(false);
            setFetchingData(false);
        }
    };

    const handleScroll = () => {
        if (!containerRef.current || searchQuery) return;

        const container = containerRef.current;
        const bottomThreshold = 20;

        if (
            container.scrollHeight - container.scrollTop <= container.clientHeight + bottomThreshold &&
            !loading &&
            !fetchingData
        ) {
            setFetchingData(true);
            setPage(prev => prev + 1);
        }
    };

    useEffect(() => {
        getAssets(page, searchQuery);
    }, [page, searchQuery]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [loading, fetchingData, searchQuery]);

    const handleScrollToTop = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleSearch = (searchId: string) => {
        setSearchQuery(searchId);
        setPage(1);
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0 });
        }
    };

    const handleNavigateToAsset = (asset_id: string) => {
        console.log(asset_id);
        // window.open(`https://postperty.com/product/${asset_id}`, '_blank');
    };

    const handleDelete = (e: React.MouseEvent, assetId: string) => {
        e.stopPropagation(); // Prevent card click event from firing
        console.log("Delete asset with ID:", assetId);
    };

    return (
        <div className="relative w-full h-full flex rounded">
            <LongDialog onSearch={handleSearch} />
            
            <div 
                className="absolute bottom-10 right-12 text-5xl bg-gray-100 z-50 rounded-full cursor-pointer hover:bg-black hover:text-white" 
                onClick={handleScrollToTop}
            >
                <MdOutlineKeyboardArrowUp />
            </div>

            <div
                ref={containerRef}
                className="grid grid-cols-12 gap-x-2 flex-wrap h-[890px] overflow-y-auto w-full mt-16"
            >
                {assetsData.map((asset, index) => {
                    const ID = asset?.id + index;
                    const imageUrl = asset?.images_url?.AssetImagesURL?.[0] ?? '';
                    const title = asset?.title;
                    const descr = asset?.description;
                    const u_name = asset?.user_data?.user.uname;
                    const asset_ID = asset?.id;
                    const soldOut = asset?.sold;

                    return (
                        <motion.div
                            className="relative mt-6 w-90 col-span-12 h-max cursor-pointer hover:bg-white-100 hover:rounded-xl md:col-span-3"
                            key={ID}
                            whileHover={{ scale: 1.05 }}
                            onClick={() => handleNavigateToAsset(asset_ID)}
                        >
                            {/* Delete Button */}
                            <div 
                                className="absolute top-8   left-6 shadow z-50 p-2 rounded-full bg-red-500 border hover:bg-red-600 text-white cursor-pointer transition-colors duration-200"
                                onClick={(e) => handleDelete(e, asset_ID)}
                            >
                                <FaBan size={16} />
                            </div>

                            <CardHeader className="relative border h-auto max-h-44 bg-black mt-6">
                                {soldOut && (
                                    <img
                                        src="https://postpertybucket01.oss-ap-southeast-7.aliyuncs.com/icon/SOLD%20OUT.svg"
                                        alt="sold out"
                                        className="absolute w-full h-full z-50 object-cover"
                                        loading="lazy"
                                    />
                                )}
                                <img
                                    src={imageUrl}
                                    alt={title}
                                    className="w-full h-full object-contain z-40"
                                    loading="lazy"
                                />
                            </CardHeader>
                            <CardBody>
                                <Typography variant="h5" color="blue-gray" className="mb-2 font-main">
                                    {title?.slice(0, 30)}
                                </Typography>
                                <Typography className="font-main">
                                    {`${descr?.slice(0, 115)}..`}
                                </Typography>
                            </CardBody>
                            <div className="pt-0 absolute right-6 top-8">
                                <Button className="bg-postperty">
                                    {u_name?.slice(0, 20)}
                                </Button>
                            </div>
                        </motion.div>
                    );
                })}

                {loading && (
                    <div className="w-full flex justify-center items-center col-span-12 font-main mt-2.5 h-12 h-screen">
                        <PuffLoader color="#000" />
                    </div>
                )}
            </div>
        </div>
    );
}