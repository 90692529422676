import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="col-span-12 flex flex-col items-center justify-center w-full h-[900px] bg-white">
      {/* Animated loading circles */}
      <div className="flex space-x-2">
        <div className="w-4 h-4 rounded-full bg-blue-500 animate-bounce" 
             style={{ animationDelay: '0s' }} />
        <div className="w-4 h-4 rounded-full bg-blue-500 animate-bounce" 
             style={{ animationDelay: '0.2s' }} />
        <div className="w-4 h-4 rounded-full bg-blue-500 animate-bounce" 
             style={{ animationDelay: '0.4s' }} />
      </div>
      
      {/* Loading text */}
      <p className="mt-4 text-gray-600 font-main">กำลังโหลดข้อมูล...</p>
    </div>
  );
};

export default LoadingAnimation;