import React, { useState, useEffect } from 'react';
import {
  Users,
  UserPlus,
  UserCheck,
  Target,
  TrendingUp,
  Award,
  ChevronUp,
  LucideIcon,
} from "lucide-react";

// Base interfaces for our data
interface Performer {
  name: string;
  leads: number;
  trend: number;
}

// Extend Performer to include user-specific details
interface LeadPerformer extends Performer {
  profile_image?: string;
  uname?: string;
  phone_number?: string;
}

interface TopLead {
  uid: string;
  uname: string;
  age: number;
  profile_image: string;
  phone_number: string;
  email_address: string;
  first_name: string;
  last_name: string;
  lead_count: string;
  lead_ids: string[];
}

interface TopLeadsResponse {
  total: number;
  top5Leads: TopLead[];
}

interface DailyStats {
  id: string;
  date: string;
  total_users: number;
  try_reg: number;
}

interface StatCardProps {
  title: string;
  value: number;
  icon: LucideIcon;
  description?: string;
  gradient: string;
  trend?: number;
  delay: number;
}

const Dashboard: React.FC = () => {
  // State variables for API data and UI control
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [dashboardStats, setDashboardStats] = useState<DailyStats | null>(null);
  const [newUsers, setNewUsers] = useState<any[]>([]);
  const [topLeads, setTopLeads] = useState<TopLeadsResponse | null>(null);

  useEffect(() => {
    const baseURL = process.env.REACT_APP_ADMIN_API;
    Promise.all([
      fetch(`${baseURL}/event/top5-leads`).then((res) => res.json()),
      fetch(`${baseURL}/event/new_user-reg-today`).then((res) => res.json()),
      fetch(`${baseURL}/event/dashboard-stats-daily`).then((res) => res.json()),
    ])
      .then(([topLeadsResponse, newUsersResponse, dashboardStatsResponse]) => {
        setTopLeads(topLeadsResponse);
        setNewUsers(newUsersResponse);
        // Assuming dashboardStatsResponse is an array, pick the latest entry.
        if (dashboardStatsResponse && dashboardStatsResponse.length > 0) {
          const latestStats =
            dashboardStatsResponse[dashboardStatsResponse.length - 1];
          setDashboardStats(latestStats);
        }
        // Simulate a loading delay for smooth UI transition
        setTimeout(() => {
          setIsLoading(false);
          setTimeout(() => setShowContent(true), 100);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error fetching dashboard data:", error);
        setIsLoading(false);
        setShowContent(true);
      });
  }, []);

  // Stat card component for summary information
  const StatCard: React.FC<StatCardProps> = ({
    title,
    value,
    icon: Icon,
    description,
    gradient,
    trend,
    delay,
  }) => {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
      const timer = setTimeout(() => setShow(true), delay);
      return () => clearTimeout(timer);
    }, [delay]);

    return (
      <div
        className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-500 ease-out ${
          show ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"
        }`}
      >
        <div className={`${gradient} p-6 text-white`}>
          <div className="flex items-center justify-between">
            <div className="bg-white/20 p-3 rounded-lg transform transition-transform duration-300 hover:scale-110">
              <Icon className="h-6 w-6" />
            </div>
            {trend ? (
              <div className="flex items-center space-x-1 bg-white/20 px-2 py-1 rounded-full text-sm">
                <ChevronUp className="h-4 w-4" />
                <span>{trend}%</span>
              </div>
            ) : null}
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-medium">{title}</h3>
            <p className="text-3xl font-bold mt-2">{value.toLocaleString()}</p>
            {description && (
              <p className="mt-2 text-sm opacity-90">{description}</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Placeholder component for loading state
  const LoadingCard: React.FC = () => (
    <div className="bg-white rounded-lg shadow-lg h-40 animate-pulse">
      <div className="h-full bg-gray-200"></div>
    </div>
  );

  // Top performer item displays user image (if available) plus details
  const TopPerformerItem: React.FC<{
    performer: LeadPerformer;
    index: number;
    show: boolean;
  }> = ({ performer, index, show }) => (
    <div
      className={`flex items-center justify-between p-4 rounded-lg hover:bg-gray-50 transition-all duration-300 transform ${
        show ? "translate-x-0 opacity-100" : "translate-x-4 opacity-0"
      }`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <div className="flex items-center space-x-4">
        {performer.profile_image ? (
          <img
            src={performer.profile_image}
            alt={performer.name}
            className="w-10 h-10 rounded-full object-cover"
          />
        ) : (
          <div
            className={`w-10 h-10 rounded-lg ${
              index === 0
                ? "bg-gradient-to-br from-yellow-400 to-orange-500"
                : index === 1
                ? "bg-gradient-to-br from-gray-300 to-gray-400"
                : index === 2
                ? "bg-gradient-to-br from-orange-300 to-orange-400"
                : "bg-gradient-to-br from-gray-200 to-gray-300"
            } flex items-center justify-center text-white font-bold transform transition-transform duration-300 hover:scale-110`}
          >
            {index + 1}
          </div>
        )}
        <div>
          <p className="font-semibold text-gray-900">{performer.name}</p>
          {performer.uname && (
            <p className="text-sm text-gray-600">Username: {performer.uname}</p>
          )}
          {performer.phone_number && (
            <p className="text-sm text-gray-600">Tel: {performer.phone_number}</p>
          )}
        </div>
      </div>
      <div className="text-right">
        <div className="text-2xl font-bold text-gray-900">
          {performer.leads}
        </div>
        <div className="text-sm text-gray-600">ลีด</div>
      </div>
    </div>
  );

  return (
    <div className="w-full min-h-screen bg-gray-50 p-8 space-y-8 mt-10">
      <div
        className={`space-y-4 transition-all duration-500 ${
          showContent ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
        }`}
      >
        <h2 className="text-4xl font-bold tracking-tight bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
          แดชบอร์ดภาพรวม
        </h2>
        <p className="text-gray-600">
          ข้อมูลการใช้งานเว็บไซต์และการสร้างลีดประจำวัน
        </p>
      </div>

      {isLoading ? (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          {[...Array(4)].map((_, i) => (
            <LoadingCard key={i} />
          ))}
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          <StatCard
            title="ผู้เข้าชมวันนี้"
            value={dashboardStats?.total_users ?? 0}
            icon={Users}
            gradient="bg-gradient-to-br from-purple-500 to-indigo-600"
            delay={200}
          />
          <StatCard
            title="พยายามสมัคร"
            value={dashboardStats?.try_reg ?? 0}
            icon={UserPlus}
            gradient="bg-gradient-to-br from-blue-500 to-cyan-600"
            delay={400}
          />
          <StatCard
            title="สมาชิกใหม่"
            value={newUsers.length}
            icon={UserCheck}
            gradient="bg-gradient-to-br from-green-600 to-green-100"
            delay={600}
            description={
              newUsers.length === 0
                ? "No new user registration today"
                : ""
            }
          />
          <StatCard
            title="ลีดใหม่วันนี้"
            value={topLeads?.total ?? 0}
            icon={Target}
            gradient="bg-gradient-to-br from-orange-500 to-yellow-600"
            delay={800}
          />
        </div>
      )}

      <div
        className={`bg-white rounded-lg shadow-lg transition-all duration-500 ease-out ${
          showContent ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        }`}
      >
        <div className="border-b border-gray-100 p-6">
          <div className="flex items-center space-x-4">
            <div className="p-3 bg-gradient-to-br from-yellow-400 to-orange-500 rounded-lg transform transition-transform duration-300 hover:scale-110">
              <Award className="h-6 w-6 text-white" />
            </div>
            <h3 className="text-xl font-semibold">อันดับสร้างลีดประจำวัน</h3>
          </div>
        </div>
        <div className="p-6">
          <div className="space-y-6">
            {topLeads && topLeads.top5Leads && topLeads.top5Leads.length > 0 ? (
              topLeads.top5Leads.map((lead, index) => {
                // Map the API data into our LeadPerformer type.
                const performer: LeadPerformer = {
                  name: `${lead.first_name} ${lead.last_name}`,
                  leads: parseInt(lead.lead_count, 10),
                  trend: 0, // Trend data is not provided by the API
                  profile_image: lead.profile_image,
                  uname: lead.uname,
                  phone_number: lead.phone_number,
                };
                return (
                  <TopPerformerItem
                    key={lead.uid}
                    performer={performer}
                    index={index}
                    show={showContent}
                  />
                );
              })
            ) : (
              <p className="text-gray-600">No lead data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
