import React, { useState } from 'react';
import axios from 'axios';

const RealEstateLeadDownload: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleDownload = async () => {
    setLoading(true);
    setError(null);
    try {
      // Retrieve the token from localStorage (or any other secure source)
      const token = localStorage.getItem('token');

      // Make the GET request to fetch the file as a Blob with the Bearer token in headers
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_API}/ML/allRealEstateLeadSentToClient`,
        {
          responseType: 'blob', // important to handle binary data
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Extract filename from the Content-Disposition header if available
      let fileName = 'RealEstateLeads.xlsx';
      const disposition = response.headers['content-disposition'];
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }

      // Create a URL for the Blob object and trigger a download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
      setError('Failed to download file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-gray-100 p-4">
      <h1 className="text-2xl font-bold mb-6">Download Real Estate Leads</h1>
      <button
        onClick={handleDownload}
        disabled={loading}
        className="px-6 py-3 bg-blue-600 text-white font-medium rounded hover:bg-blue-700 transition duration-200 disabled:bg-blue-400"
      >
        {loading ? 'Downloading...' : 'Download Excel File'}
      </button>
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </div>
  );
};

export default RealEstateLeadDownload;
